<div *ngIf="!loader" [ngClass]="{'grid-product': horizontal}">
    <div class="img-wrapper">
      <div class="lable-block">
        <span class="lable3" *ngIf="discountedPrice">- {{discountedPrice}}%</span>
      </div>
      <a [routerLink]="['/home/product']" (click)="redirectProduct()" class="front">
        <img 
          [defaultImage]="'assets/images/product/placeholder.jpg'" 
          [lazyLoad]="product.image" 
          [alt]="product.fldName" 
          class="img-fluid lazy-loading" />
      </a>

      <div class="love-it" (click)="addRemoveFav(product)">
        <svg *ngIf="!fav" width="30" height="30" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.80344 1.36581C10.1042 0.509143 11.8322 0.272476 13.325 0.77081C16.5722 1.86164 17.5802 5.54914 16.6786 8.48331C15.2874 13.0916 9.34584 16.5291 9.09384 16.6733C9.00424 16.725 8.90504 16.7508 8.80585 16.7508C8.70665 16.7508 8.60824 16.7258 8.51864 16.675C8.26824 16.5325 2.36984 13.1458 0.932244 8.48414C0.931444 8.48414 0.931444 8.48331 0.931444 8.48331C0.0290437 5.54831 1.03384 1.85998 4.27784 0.77081C5.80104 0.257476 7.46104 0.48331 8.80344 1.36581ZM4.64584 1.96081C2.02104 2.84248 1.36184 5.78331 2.07464 8.10248C3.19624 11.7375 7.62744 14.6766 8.80504 15.4041C9.98664 14.6691 14.4498 11.6975 15.5354 8.10581C16.2482 5.78414 15.5866 2.84331 12.9578 1.96081C11.6842 1.53498 10.1986 1.79414 9.17304 2.62081C8.95864 2.79248 8.66105 2.79581 8.44504 2.62581C7.35864 1.77498 5.93944 1.52581 4.64584 1.96081ZM12.1898 3.61573C13.2802 3.98323 14.0442 4.98906 14.1378 6.17823C14.1642 6.52239 13.9186 6.82406 13.5882 6.85156C13.5714 6.85323 13.5554 6.85406 13.5386 6.85406C13.229 6.85406 12.9666 6.60656 12.941 6.27989C12.8882 5.59489 12.4482 5.01656 11.8218 4.80573C11.5058 4.69906 11.333 4.34656 11.4346 4.01906C11.5378 3.69073 11.873 3.51239 12.1898 3.61573Z" fill="#222222"/>
        </svg>
        <svg *ngIf="fav" class="fill" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.38 16.25"><defs><style>.cls-1{fill:#ad0f31;}.cls-1,.cls-2{fill-rule:evenodd;}.cls-2{fill:none;}</style></defs><path class="cls-1" d="M8.8,1.37a5.3,5.3,0,0,1,4.52-.6c3.25,1.09,4.26,4.78,3.36,7.71-1.39,4.61-7.33,8.05-7.59,8.19a.48.48,0,0,1-.28.08.51.51,0,0,1-.29-.08c-.25-.14-6.15-3.52-7.59-8.19h0C0,5.55,1,1.86,4.28.77A5.17,5.17,0,0,1,8.8,1.37Zm3.39,2.25a2.92,2.92,0,0,1,2,2.56.62.62,0,0,1-.55.67h-.05a.61.61,0,0,1-.6-.57,1.65,1.65,0,0,0-1.12-1.47A.64.64,0,0,1,11.43,4,.6.6,0,0,1,12.19,3.62Z" transform="translate(-0.62 -0.5)"/><path class="cls-2" d="M12.19,3.62a2.92,2.92,0,0,1,2,2.56.62.62,0,0,1-.55.67h-.05a.61.61,0,0,1-.6-.57,1.65,1.65,0,0,0-1.12-1.47A.64.64,0,0,1,11.43,4,.6.6,0,0,1,12.19,3.62Z" transform="translate(-0.62 -0.5)"/></svg>        
      </div>
      
    </div>
    <div class="product-detail">
        <div class="prices">
            <span> Bs {{ product.formatPriceDiscount }}</span>
            <span class="muted-color tached" *ngIf="discountedPrice">Bs {{ product.formatPrice }}</span>
        </div>
        <p class="small">{{ product?.category }}</p>
        <p class="sumary">{{ product?.fldName }} <br>{{ product?.cartSummary }} </p>
        <p *ngIf="!horizontal" class="small">SKU:{{ product?.pkyItem }}</p>
    </div>
    <button class="btn btn-primary-light icon" [ngClass]="{'d-none': horizontal}" (click)="addProductShoppingCart()" *ngIf="!showProductValue">
        <img src="../../../../../assets/la-cascada/icons/buy.svg" alt="icon">
        Añadir
    </button>
    
    <div class="btn-border" *ngIf="showProductValue">
      <div class="qty-box minified">
        <div class="input-group">
            <span class="input-group-prepend">
                <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="removeProductValue()">
                    <i class="ti-minus"></i>
                </button>
            </span>
            <input type="text" #quantityProduct id="quantity" class="form-control" [value]="productValue" disabled> 
            <span class="paq">Paq.</span>
            <span class="input-group-prepend">
                <button type="button" class="btn quantity-right-plus"  data-type="plus" (click)="addProductValue()">
                    <i class="ti-plus"></i>
                </button>
            </span>
        </div>
      </div>
      <div class="icons-quantity">
        <i class="ti-check" (click)="confirmShoppingCart(product, quantityProduct.value)"></i>
        <i class="ti-close" (click)="hideProductShoppingCart()"></i>
      </div>
    </div>
  
    <!-- <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]">
      <h6>{{ product?.title | titlecase }}</h6>
    </a> -->
    <!-- <h4>
      {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
      <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
    </h4> -->
    <!-- <ul class="color-variant" *ngIf="Color(product?.variants).length">
      <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
        (click)="ChangeVariants(color, product)">
      </li>
    </ul> -->
  </div>
  <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
  <!-- <app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
  <app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal> -->
  
  