import { Component, OnInit, Input, HostListener } from '@angular/core';
import { LaCascadaProductService } from '../../services/la-cascada-product.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/la-cascada/logo.svg'; // Default Logo
  // @Input() topbar: boolean = true; 
  @Input() sticky: boolean = false; // Default false

  hide: boolean = true;
  
  public stick: boolean = false;

  // selectedCity = JSON.parse(localStorage.getItem('selectedCity')) || ['1', 'assets/la-cascada/icons/lapaz.svg'];
  formSearch = new FormGroup({
    search : new FormControl('')
  })
  
  constructor(public laCascadaProductService: LaCascadaProductService, public router: Router) { 
    this.laCascadaProductService.getCities();
    this.laCascadaProductService.clearSearchQuery$
      .subscribe(( resp:boolean )=>{
        if(resp){
          this.clearSearchQuery();
        }
      })
  }

  ngOnInit(): void {
  }

  get cities(): any[]{
    return this.laCascadaProductService.cities;
  }

  get userInformation(): any {
    return this.laCascadaProductService.userInformation;
  }
  

  searchQuery(){
    this.laCascadaProductService.search = !this.laCascadaProductService.search;
    this.laCascadaProductService.searchProduct(this.formSearch.get('search').value)  
    this.router.navigate(['/home/products']);
  }
  
  changeCity(){
    var e = <HTMLInputElement>document.getElementById("header-select-2");
    var localData: string[] = []; 
    localData[0] = e.value ;
    localData[1]= this.cities[Number(localData[0]) - 1].urlImage;

    localStorage.setItem( 'selectedCity', JSON.stringify(localData) );
    this.laCascadaProductService.citySelected = localData;
    
    window.location.reload();
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

  hideMenu(){
    this.hide = false;
  }

  logOut(){
    this.laCascadaProductService.postLogOut({ userId: this.userInformation.pkyBill });
  }

  sliceName(name: string){
    if(name == undefined){
      name = '';
    }
    var newName: string = name;
    if(name.split(' ')[1] != undefined){
      newName = name.split(' ')[0];
    }
    return newName;
  }

  clearSearchQuery(){
    this.formSearch.reset();
  }

}
