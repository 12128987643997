import { Injectable } from '@angular/core';
import { ProductLaCascada } from '../classes/product-la-cascada';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LaCascadaProductService {

  // private _url: string = 'https://lacascadacrmtesting.solunes.site/api';
  private _url: string = 'https://lacascadacrm.test.solunes.com/api';
  private _auth_token: string = localStorage.getItem('LaCascadaToken') || 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9jYXNjYWRhLWNybS50ZXN0XC9hcGlcL2xvZ2luVXNlckNsaWVudCIsImlhdCI6MTY3ODIxMDM5NywiZXhwIjoxNjc4MjEzOTk3LCJuYmYiOjE2NzgyMTAzOTcsImp0aSI6IldXQWRCWjVVUmhMQUFwdUUiLCJzdWIiOjEsInBydiI6ImRjNWM2MmMyODdkOTcyZTE1MjdmOWVkYzQwZDJjMzMxMThjMjljNDQifQ.Sy2bvvod_esOV-ffjElJXy51gHbualazN6SFZtNQllA';
  private _userInformation: any = JSON.parse(localStorage.getItem('LaCascadaUser')) || {};
  private _addresses: any[] = JSON.parse(localStorage.getItem('LaCascadaAddresses')) || [];
  private _paymentMethods: any[];
  private _orderHistory: any[] = [];
  private _ordersHistoryTotal: any = {};
  private _targetOrder: any = {};
  private _storageUnits: any = [];
  public lastOrder: any = JSON.parse(localStorage.getItem('LaCascadaLastOrder')) || [];
  public favoriteAddress: number = Number(localStorage.getItem('LaCascadaFavAdd')) || 0;
  public favorites: any[] = JSON.parse(localStorage.getItem('LaCascadaFavs')) || [];
  public querySearch: string = '';
  public hideWidget: boolean = false;
  
  private _cities: any[] = [];
  private _brands: any[] = [];
  private _categories: any[] = [];
  private _productXcategory: any[] = [];
  private _productXcategorySlider: any[] = [];
  private _faqs: any[] = [];
  private _categorySeparated : any = [[],[],[],[],[],[],[],[],[],[],[],[]];
  public latLong: number[] = [];
  public searchProductResults: any[] = [];
  public searchProductResultsBrands: any[] = [];
  public searchProductResultsStorage: any[] = [];
  public searchMerge: any[] = [];
  public searchFlag: any[] = [false, ''];
  public flagTitleLogin: boolean = false;
  public searchFlagProductsMerged: boolean = false;
  public showOriginal: boolean = true;
  public actualBrand: number = 0;
  public filtersBrands: boolean [] = [];
  public filterStorageUnits: boolean [] = [];
  public showBanner: boolean = false;
  public search: boolean = false;
  public httpLoginResult: string = '';
  public guestFlag: string = localStorage.getItem('guestFlag') || '1';
  public shopFlow: string =  localStorage.getItem('shopFlow') || '0';
  
  public _shoppingCart: any = JSON.parse(localStorage.getItem('LaCascadaCart')) || [];
  public citySelected: string[] = JSON.parse(localStorage.getItem('selectedCity')) || ['1', 'assets/la-cascada/icons/lapaz.svg'];
  public productSelected: any = JSON.parse(localStorage.getItem('selectedProduct')) || { };
  public categorySelected: any = localStorage.getItem('selectedCategory') || ['1'];
  public categorySelectedName: string = localStorage.getItem('categorySelectedName') || 'Ofertas' ;
  public favoriteSelected: any[] = JSON.parse(localStorage.getItem('selectedFavorite')) || [false, ''];
  public actualStep = 1;
  public loader = document.getElementById('loader-full');
  public flagHeaderFilter: boolean[] = [false, false];

  public refreshMarker$ = new BehaviorSubject<number[]>([]);
  public changeCategory$ = new BehaviorSubject<string>('');
  public updateTotalCart$ = new BehaviorSubject<boolean>(false);
  public clearSearchQuery$ = new BehaviorSubject<boolean>(false);
  
  constructor( private http: HttpClient, private toastrService: ToastrService, private router: Router) {
    if(this.guestFlag == '1'){
      this.postGuestUser();
    }
  }
  
  get brands(): any[] {
    return [...this._brands];
  }
  
  get cities(): any[] {
    return [...this._cities];
  }
  
  get categories(): any[] {
    return [...this._categories];
  }

  get productXcategory() {
    return [...this._productXcategory];
  }
  
  get productXcategorySlider() {
    return [...this._productXcategorySlider];
  }

  get faqs(){
    return [...this._faqs];
  }

  get categorySeparated() {
    return [...this._categorySeparated];
  }

  get userInformation() {
    return {...this._userInformation};
  }
  
  get shoppingCart() {
    return this._shoppingCart;
  }
  
  get addresses() {
    return [...this._addresses];
  }
  
  get paymentMethods() {
    return this._paymentMethods;
  }
  
  get ordersHistory() {
    return this._orderHistory;
  }

  get ordersHistoryTotal() {
    return {...this._ordersHistoryTotal};
  }
  
  get targetOrder() {
    return {...this._targetOrder};
  }
  
  get storageUnits() {
    return [...this._storageUnits];
  }

  hideShoppingWidget(){
    var blackout = document.getElementById('blackout-widget');
    if(this.hideWidget == true){
      this.hideWidget = false;
      blackout.classList.remove('visual-active');
      setTimeout(() => {
        blackout.classList.remove('active');
      }, 200);
    }else{
      this.hideWidget = true;
      blackout.classList.add('active');
      blackout.classList.add('visual-active');
    }
  }
  searchToggle(){
    this.search = !this.search;
  }

  searchToggleOff(){
    this.search = false;
    this.clearSearchQuery$.next(true);
  }

  searchProduct(value: string){
    this.searchProductResults = [];
    this.searchMerge = [];
    this.flagHeaderFilter[1] = false;
      this.productXcategory.forEach(element => {
        if(element.fldName.toLowerCase().includes(value.toLowerCase()) ){
         this.searchProductResults.push(element);
        }
      });
    
    for (let index = 0; index < this.filterStorageUnits.length; index++) {
      this.filterStorageUnits[index] = false;
    }

    if(this.searchProductResults.length != 0){
      this.searchFlag[0] = false;
      this.searchFlagProductsMerged = false;
    }else{
      this.searchFlag[0] = true;
      this.searchFlag[1] = value;
    }
    
    if(value = ''){
      this.showOriginal = true;
    }else{
      this.showOriginal = false;
    }
  }

  searchStorageUnits(value: number, description: string){
    
    this.searchProductResults = [];
    this.searchFlag[0] = false;
    this.searchToggleOff();

    this.flagHeaderFilter[1] = true;
    for (let index = 0; index < this.filterStorageUnits.length; index++) {
      this.filterStorageUnits[index] = false;
    }
    
    this.filterStorageUnits[value] = true;
    this.searchProductResultsStorage = [];

    this._productXcategory.forEach(element => {
      for (let index = 0; index < element.storage_item_units.length; index++) {
        if (value == element.storage_item_units[index].codStorageUnit) {
          this.searchProductResultsStorage.push(element);
        }
      }
    });


    if(this.searchProductResultsBrands.length != 0){
      this.searchMerge = [];
      this.searchProductResultsBrands.forEach(element => {
        this.searchProductResultsStorage.forEach(element2 => {
          
          if (element.pkyItem == element2.pkyItem) {
            this.searchMerge.push(element2);
          }
        });
      });
    }else{
      this.searchMerge = this.searchProductResultsStorage;
    }

    this.searchFlagProductsMerged = true;

    if(this.searchMerge.length != 0){
      this.searchFlagProductsMerged = false;
      this.showOriginal = false;

      if(this.actualBrand >= 1 && this.actualBrand <= 7){
        console.log(this.actualBrand);
        
        this.showBanner = true;
      }
    }
    
    this.searchFlag[1] = description;
    
  }

  changeBrand( value: number, name: string ){

    this.searchProductResults = [];
    this.searchFlag[0] = false;
    this.searchToggleOff();

    this.flagHeaderFilter[0] = true;
    var newName = name.toLowerCase();
    
    this.categorySelectedName = newName.charAt(0).toUpperCase() + newName.slice(1);
    
    if(newName.includes('villasanta')){
      this.actualBrand = 1;
    }
    if(newName.includes('viscachani')){
      this.actualBrand = 2;
    }
    if(newName.includes('sport')){
      this.actualBrand = 3;
    }
    if(newName.includes('planet')){
      this.actualBrand = 4;
    }
    if(newName.includes('sabores') || newName.includes('cascafrut')){
      this.actualBrand = 5;
    }
    if(newName.includes('coka')){
      this.actualBrand = 6;
    }
    if(newName.includes('jumbo')){
      this.actualBrand = 7;
    }

    for (let index = 0; index < this.filtersBrands.length; index++) {
     this.filtersBrands[index] = false;
    }
    this.filtersBrands[value] = true;
    
    localStorage.setItem('categorySelectedName', this.categorySelectedName);
    // this.getProductXCityXBrand( this.citySelected[0] , value.toString());
    
    this.searchProductResultsBrands = [];

    this._productXcategory.forEach(element => {
      if (value.toString() == element.storage_brand.pkyItemBrand) {
        this.searchProductResultsBrands.push(element);
      }
    });
    
    // if(this.searchProductResultsStorage.length != 0){
    //   this.searchMerge = [];
    //   this.searchProductResultsStorage.forEach(element => {
    //     this.searchProductResultsBrands.forEach(element2 => {
          
    //       if (element2.pkyItem == element.pkyItem) {
    //         this.searchMerge.push(element2);
    //       }
    //     });
    //   });
    // }else{
    //   this.searchMerge = this.searchProductResultsBrands;
    // }
    this.searchProductResultsStorage = [];
    this.searchMerge = this.searchProductResultsBrands;
    
    this.searchFlagProductsMerged = false;
    this.flagHeaderFilter[1] = false;

    // if(this.searchMerge.length != 0){
    //   this.searchFlagProductsMerged = false;
    // }
    this.showOriginal = false;
    this.showBanner = true;

    for (let index = 0; index < this.filterStorageUnits.length; index++) {
      this.filterStorageUnits[index] = false;
    }
  }

  objetEmpty(obj: any){    
    let response: boolean = false;

    if(obj == undefined){
      obj = [];
    }

    if(Object.keys(obj).length == 0){
      response = true;
    }
    return response;
  }

  // get request

  getBrands(codFamily: string){

    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`)
    .set('codFamily', codFamily);

    this.loader.classList.add('show');

    this.http.get(this._url + '/model/InvItemBrand', { params })
      .subscribe( ( resp:any )=>{
        
        this._brands = resp.items.data;
        // // console.log(this._categories);
        this.loader.classList.remove('show');
    })
  }

  getCategories(codcity: string){
    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`)
    .set('codCity', codcity);

    this.loader.classList.add('show');

    this.http.get(this._url + '/model/InvFamily', { params })
      .subscribe( ( resp:any )=>{
        
        this._categories = resp.items.data;
        // console.log(this._categories);
        this.loader.classList.remove('show');
    })
  }
  getCategoriesToSubscribe(codcity: string){
    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`)
    .set('codCity', codcity);

    return this.http.get(this._url + '/model/InvFamily', { params })
  }

  getCities(){
    let params = new HttpParams()
    .set('Authorization', `Bearer ${this._auth_token}`);

    // this.loader.classList.add('show');
    this.http.get(this._url + '/model/InvCity', { params })
      .subscribe( ( resp:any )=>{
        // console.log(this._cities);
        
        this._cities = resp.items.data;
        // this.loader.classList.remove('show');
    });
  }
  
  getSpecificProductXCity( codcity :string, codProduct: number ){
    this.productSelected = {};
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codCity', codcity);

    this.loader.classList.add('show');

    this.http.get(this._url + `/model/InvItem`, { params })
      .subscribe( ( resp: any )=>{
        // this._productXcategory = resp.items;
        
        resp.items.forEach(element => {
          if (codProduct == element.pkyItem) {
            this.productSelected = element;
            localStorage.setItem( 'selectedProduct', JSON.stringify(this.productSelected));
          }
        });
        this.loader.classList.remove('show');
    });
  }
  
  getProductXCity( codcity :string, query: number ){
    this._productXcategory = [];
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codCity', codcity);

    this.loader.classList.add('show');
    
    this.http.get(this._url + `/model/InvItem`, { params })
      .subscribe( ( resp: any )=>{
        // this._productXcategory = resp.items;
        resp.items.forEach(element => {
          // console.log(element.storage_item_units[0].codStorageUnit, element.storage_item_units[0].txtDescription);
          if (query == element.storage_item_units[0].codStorageUnit) {
            this._productXcategory.push(element);
          }
        });
        
        this.loader.classList.remove('show');
    });
  }
  
  getProductXCityXBrand( codcity :string, codbrand: string ){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codBrand', codbrand)
      .set('codCity', codcity);

    this.loader.classList.add('show');
    this.http.get(this._url + `/model/InvItem`, { params })
      .subscribe( ( resp: any )=>{
        this._productXcategory = resp.items;
        
        this.loader.classList.remove('show');
    });
  }

  getFaqsQuestions(){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('type', 'help');

    this.loader.classList.add('show');
    this.http.get(this._url + `/model/InvTextSupport`, { params })
      .subscribe( ( resp: any )=>{
        this._faqs = resp.items.data;
        this.loader.classList.remove('show');
    });
  }

  getFavorites(){
  if (this.guestFlag == '0') {
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codUser', this._userInformation.pkyBill);

      // this.loader.classList.add('show');

      this.http.get(this._url + `/model/InvItem`, { params })
        .subscribe( ( resp: any )=>{
          this.favorites = [];
          resp.items.forEach(element => {
            if(element.favourite){
              this.favorites.push(element);
            }
          });
            localStorage.setItem('LaCascadaFavs', JSON.stringify(this.favorites));
            // this.loader.classList.remove('show');
        });
    }
  }

  getShoppingCart(){
    if (this.guestFlag == '0') {      
      let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codUser', this._userInformation.pkyBill);

      // this.loader.classList.add('show');

      this.http.get(this._url + `/model/SlsCart`, { params })
        .subscribe(( resp: any )=>{
          this._shoppingCart = resp.items.data[0];
          // this.loader.classList.remove('show');
        })
      }      
  }

  getShoppingCartToSubscribe(){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codUser', this._userInformation.pkyBill);

    

    return this.http.get(this._url + `/model/SlsCart`, { params })
  }

  getAddresses(){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codUser', this._userInformation.pkyBill);

    // this.loader.classList.add('show');

      this.http.get(this._url + `/model/UserAddress`, { params })
      .subscribe(( resp: any )=>{
        if(resp.status){
          if(resp.items.data.length != 0){
            this._addresses = resp.items.data;          
          }
          // guest user
          localStorage.setItem('LaCascadaAddresses', JSON.stringify(this._addresses));
        }else{
          this.toastrService.error(`Hubo un error al obtener las direcciones, por favor recarga la página`, '', {timeOut: 5000} );
        }
          // this.loader.classList.remove('show');
      })
  }

  getAddressesToSubscribe(){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codUser', this._userInformation.pkyBill);

      return this.http.get(this._url + `/model/UserAddress`, { params })
  }
  
  getPaymentMethods(){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codUser', this._userInformation.pkyBill);

    // this.loader.classList.add('show');

      this.http.get(this._url + `/model/InvPaymentMethod`, { params })
      .subscribe(( resp: any )=>{
        this._paymentMethods = resp.items.data;
        // this.loader.classList.remove('show');
    });
  }
  
  getPaymentMethodsToSubscribe(){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codUser', this._userInformation.pkyBill);

    // this.loader.classList.add('show');

      return this.http.get(this._url + `/model/InvPaymentMethod`, { params })
  }
  
  getOrderHistory(){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    this.loader.classList.add('show');

    this.http.get(this._url + `/postOrders`, { headers })
      .subscribe(( resp: any )=>{
        this._orderHistory = resp.item.pendientes;
        this.loader.classList.remove('show');
    });
  }

  getOrdersHistoryTotal(){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    this.loader.classList.add('show');

    this.http.get(this._url + `/postOrders`, { headers })
      .subscribe(( resp: any )=>{
        this._ordersHistoryTotal = resp.item;
        console.log(this._ordersHistoryTotal, 'ordersHistoryTotal');
        
        this.loader.classList.remove('show');
    },(error: any) =>{
      this.loader.classList.remove('show');
      this.router.navigate(['/home/login']);
      this.toastrService.error(`Inicia sesión nuevamente para ver tu historial`, '', {timeOut: 5000} );
    });
  }
  
  getTargetOrder(query: number){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('pkyOrder', query);

    this.loader.classList.add('show');

      this.http.get(this._url + `/model/SlsOrder`, { params })
      .subscribe(( resp: any )=>{
        this._targetOrder = resp.items;
        
        this.loader.classList.remove('show');
    });
  }
  
  getTargetOrderToSubscribe(query: number){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('pkyOrder', query);

      return this.http.get(this._url + `/model/SlsOrder`, { params })
  }

  getStorageUnits(code: string){    
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codFamily', code);

    this.loader.classList.add('show');

      this.http.get(this._url + `/model/InvStorageUnit`, { params })
      .subscribe(( resp: any )=>{
        this._storageUnits = resp.items;
        this.loader.classList.remove('show');
    });
  }

  getProductXCityXCategory( codcity :string, codfamily: string, type :string ){    
    
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      .set('codFamily', codfamily)
      .set('codCity', codcity);

    this.loader.classList.add('show');

      this.http.get(this._url + `/model/InvItem`, { params })
      .subscribe( ( resp: any )=>{
        switch (type) {
          case 'slider':
            this._productXcategorySlider = resp.items;
          break;
          case 'separate':
            this._categorySeparated[Number(codfamily) - 1] = resp.items;
          break;
          default:
            this._productXcategory = resp.items;
          break;
        } 
        this.loader.classList.remove('show');
    });
  }

  get_GoogleLogin() {
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });
    
    // return this.http.get( this._url + `/login/google`, { headers })
    //   .pipe( map((resp:any) => {
    //     console.log(resp);
        
    //   })
    // )
    fetch('https://lacascadacrm.test.solunes.com/api/login/google', {
      headers: {Authorization: `Bearer ${this._auth_token}`},
    })
      .then(response => response.json())
      .then(json => console.log(json))
  }

  getGoogleLogin(){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`,
    });

    console.log(headers);
    

    this.http.get( this._url + `/login/google`, { headers })
      .subscribe(( resp: any )=>{
        console.log(resp);
        
          this.httpLoginResult = resp;
      }, (error: any)=>{
        this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }

  // post requests

  postRegisterNewUser(fields: any){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
    
    this.http.post(this._url + `/registerUserClient`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){
          var loginData: object= {
            userName: fields.userName,
            password: fields.password
          }

          this.toastrService.success(`Usuario registrado correctamente`, '', {timeOut: 5000});
          this.postLoginUser(loginData, false);
        }else{
          this.toastrService.error(`${resp.errors}`, '', {timeOut: 5000} );
        }
    });
  }
  
  postEditUser(fields: any, message?: boolean){    
    if(message == undefined){
      message = true;
    }
    
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
      
    this.http.post(this._url + `/updateUserClient`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){
          localStorage.setItem('LaCascadaUser', JSON.stringify(resp.item.user));
          this._userInformation = resp.item.user;
          // if(this.shopFlow == '1'){
          //   this.router.navigate(['/home/shopping-cart-details']);
          //   this.shopFlow = '0';
          //   localStorage.setItem('shopFlow', this.shopFlow);
          // }else{
          //   this.router.navigate(['/home/featured']);
          // }
          if(message){
            this.router.navigate(['/home/featured']);
            this.toastrService.success(`Usuario actualizado correctamente`, '', {timeOut: 5000});
          }
        }else{
          this.toastrService.error(`${resp.errors}`, '', {timeOut: 5000} );
        }
    }, (error: any)=>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    }
    );
  }
  
  postLoginUser(fields: any, message?: boolean){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)

    if(message == undefined){
      message = true;
    }
    
    this.http.post(this._url + `/loginUserClient`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){
          // this.router.navigate(['/home/featured']);
          if(message){
            this.toastrService.success(`Bienvenido de nuevo ${resp.item.user.firstName}`, '', {timeOut: 5000});
          }
          this._auth_token = resp.token;
          localStorage.setItem('LaCascadaToken', resp.token);
          localStorage.setItem('LaCascadaUser', JSON.stringify(resp.item.user));
          this._userInformation = resp.item.user;
          
          if(this.shoppingCart.length != 0){
            this.router.navigate(['/home/shopping-cart-details']);
          }else{
            this.router.navigate(['/home/location']);
          }

          this.guestFlag = '0';
          localStorage.setItem('guestFlag', this.guestFlag);

          // guest favorites placement
          this.favorites.forEach(element => {  
            var addParams = { 
              codItem: element.pkyItem,
              codUser: this.userInformation.pkyBill
            }
            this.postAddFav(addParams)
            .subscribe(( resp: any )=>{
              if(resp.status)
              {
                this.favoriteSelected[0] = true;
                this.favoriteSelected[1] = resp.item.pkyItemFavourite;
                localStorage.setItem( 'selectedFavorite', JSON.stringify(this.favoriteSelected));
              }else{
                // this.toastrService.error(`${resp.mensaje}`, '', {timeOut: 5000} );
              }
            });
          });
          this.getFavorites();          

          // guest shoppingCart placement
          if(this.shoppingCart?.storage_cart_detail?.length != undefined){
            var oldShoppingCart = this._shoppingCart;

            this.toastrService.info('<div class="toastr-buttons">Prueba buscando en otra categoría o marca.</div>', '¿Deseas agregar los productos de tu anterior carrito a tu compra actual?', {timeOut: 0, extendedTimeOut: 0, tapToDismiss: false } );
            setTimeout(() => {
              var toastrButtons = document.querySelector('.toastr-buttons');
              toastrButtons.innerHTML = '<div class="d-flex gap-2 flex-wrap mt-2"><button class="btn btn-xsm btn-white add-option-toast">Agregar</button> <button class="btn btn-xsm btn-white hide-option-toast">No agregar</button></div>';
              
              var hideOptionToast = document.querySelector('.hide-option-toast');
              var addOptionToast = document.querySelector('.add-option-toast');
              hideOptionToast.addEventListener("click", (e: any)=>{
                var toastrElement = e.currentTarget.parentNode.parentNode.parentNode.parentNode;
                setTimeout(() => {
                  toastrElement.style.display = 'none';
                }, 300);
                toastrElement.style.opacity = '0';
                
              });

              addOptionToast.addEventListener("click", (e: any)=>{
                var toastrElement = e.currentTarget.parentNode.parentNode.parentNode.parentNode;
                setTimeout(() => {
                  toastrElement.style.display = 'none';
                }, 300);
                toastrElement.style.opacity = '0';
                
                // this.getShoppingCartToSubscribe()
                //   .subscribe((newCart: any)=>{               
                //     if(newCart.items.data[0].storage_cart_detail.length !=0){
                //       this.toastrService.info(`Productos agregados correctamente a la compra pendiente`, '', {timeOut: 5000, extendedTimeOut: 0,} );
                //     }else{
                //       this.toastrService.info(`Productos nuevos agregados al carrito`, '', {timeOut: 5000} );
                //     }
                //   })

                // adding products                
                oldShoppingCart.storage_cart_detail?.forEach(element => {  
                  this.postAddProductShoppingCartToSubscribe({ codItem: element.codItem, fldQuantity: element.fldQuantity })
                    .subscribe(( resp: any )=>{
                      if(resp.status){
                        this.getShoppingCart();
                      }else{
                        this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
                      }
                    });
                  });
                });
            }, 100);

            
          }
          this.getShoppingCart();              
          

          // guest directions placement
          var old_Addresses = this._addresses;
          this.getAddressesToSubscribe()
            .subscribe((new_addresses: any)=>{
              if(new_addresses.items.data.length != 0){
                this._addresses = new_addresses.items.data;                  
              }
              // guest user
              
              old_Addresses.forEach(element => { 
                var coincidence = false;
                this._addresses.forEach(newElement =>{
                  if(element.alias.localeCompare(newElement.alias, 'en', {sensitivity: 'base'}) == 0 && 
                     element.fldAddress.localeCompare(newElement.fldAddress, 'en', {sensitivity: 'base'}) == 0 &&
                     element.fldDistrict.localeCompare(newElement.fldDistrict, 'en', {sensitivity: 'base'}) == 0){
                    
                      this.toastrService.error(`Dirección existe ( No fue agregada)`, '', {timeOut: 5000} );
                      coincidence = true;
                      return;
                  }
                })
                if( !coincidence ){
                  var direction = {
                    alias: element.alias,
                    fldLatitude: element.fldLatitude,
                    fldLongitude: element.fldLongitude,
                    fldAddress: element.fldAddress,
                    fldDistrict: element.fldDistrict,
                    fldReference: element.fldReference,
                    type: element.type,
                    deliverySchedule: element.deliverySchedule,
                    codUser: this.userInformation.pkyBill,
                    favoriteAddress: true,
                    id: ""
                  }
      
                  this.postAddNewAddressToSubscribe(direction)
                    .subscribe(( resp: any )=>{
                      if(resp.status){
                        if(direction.favoriteAddress){
                          this.favoriteAddress = resp.item.pkyAddress;
                          localStorage.setItem('LaCascadaFavAdd', resp.item.pkyAddress);
                        }
                      }else{
                        this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
                      }
                  }); 
                }
              });
              this.getAddresses();
            })
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    });
  }

  postForgotPass(fields: any ){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
    
    this.http.post(this._url + `/logRecoverPassword`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){
          console.log(resp);
          
          this.toastrService.success(`Se envió un código de recuperación tu correo`, '', {timeOut: 5000});
          this.actualStep = 2;
          // this.router.navigate(['/home/login'])
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    },(error: any)=>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }
  
  postForgotPassToSubscribe(fields: any ){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
    
    return this.http.post(this._url + `/logRecoverPassword`, fields, { params } )
      
  }

  postConfirmForgotPass(fields: any){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
    
    this.http.post(this._url + `/logChangePassword`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){
          this.toastrService.success(`Contraseña restablecida con exito, inicia sesión nuevamente`, '', {timeOut: 5000});
          this.actualStep = 1;
          this.router.navigate(['/home/login'])
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    },(error: any)=>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }
  
  postLogOut(fields: any){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`)
    
      this.http.post(this._url + `/logOutUserClient`, fields, { params } )
      .subscribe(( resp: any )=>{
        if(resp.status){
          this.guestFlag = '1';
          localStorage.setItem('guestFlag', this.guestFlag);
          this.router.navigate(['/home/location']);
          this.searchToggleOff();
          this._auth_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9jYXNjYWRhLWNybS50ZXN0XC9hcGlcL2xvZ2luVXNlckNsaWVudCIsImlhdCI6MTY3ODIxMDM5NywiZXhwIjoxNjc4MjEzOTk3LCJuYmYiOjE2NzgyMTAzOTcsImp0aSI6IldXQWRCWjVVUmhMQUFwdUUiLCJzdWIiOjEsInBydiI6ImRjNWM2MmMyODdkOTcyZTE1MjdmOWVkYzQwZDJjMzMxMThjMjljNDQifQ.Sy2bvvod_esOV-ffjElJXy51gHbualazN6SFZtNQllA';
          localStorage.removeItem('LaCascadaToken');
          localStorage.removeItem('LaCascadaUser');
          localStorage.removeItem('LaCascadaFavs');
          localStorage.removeItem('LaCascadaCart');
          localStorage.removeItem('LaCascadaAddresses');
          localStorage.removeItem('LaCascadaFavAdd');
          this._userInformation = {};
          // this.toastrService.success(`Session cerrada con exito`, '', {timeOut: 5000});
          window.location.reload();
        }else{
          this.toastrService.error(`${resp.mensaje}`, '', {timeOut: 5000} );
        }
    });
  }

  postRemoveFav(fields: any){
    
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`);
    
      return this.http.post(this._url + `/model/InvItemFavourite/delete`, fields, { params } );
  }

  postAddFav(fields: any): any{
    let params = new HttpParams ()
    .set('Authorization', `Bearer ${this._auth_token}`);
    
    return this.http.post(this._url + `/model/InvItemFavourite/create`, fields, { params } );
  }
  
  postAddProductShoppingCart(raw: any){
    var newFields = {
      codItem: raw.product.pkyItem, 
      fldQuantity: raw.fldQuantity 
    }
    var flagCoincidence = false;
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });
    
    this.http.post(this._url + `/registerNewProductCart`, newFields, { headers })
    .subscribe(( resp: any )=>{
      if(resp.status){
          this.getShoppingCart();
          setTimeout(() => {
            this.hideShoppingWidget();
          }, 600);
          this.toastrService.success(`Producto agregado al carrito de compras`, '', {timeOut: 5000});
        if(this.guestFlag == '1'){
          // guest user
          for (let index = 0; index < resp.item.cart.storage_cart_detail.length; index++) {
            if(resp.item.cart.storage_cart_detail[index].codItem == raw.product.pkyItem){
              if(this.shoppingCart.length == 0){
                this._shoppingCart = resp.item.cart;
              }else{
                this.shoppingCart.storage_cart_detail?.forEach(element => {
                  if( element.codItem == raw.product.pkyItem ){
                    if(resp.item.cart.pkyCart == this._shoppingCart.pkyCart){
                      element.fldQuantity = resp.item.cart.storage_cart_detail[index].fldQuantity;
                    }else{
                      element.fldQuantity = element.fldQuantity + resp.item.cart.storage_cart_detail[index].fldQuantity;
                    }
                    
                    flagCoincidence = true;
                  }
                });
              
                if(flagCoincidence == false){
                  this._shoppingCart.storage_cart_detail.push(resp.item.cart.storage_cart_detail[index]);
                }
              }
            }
          }
          localStorage.setItem('LaCascadaCart', JSON.stringify(this._shoppingCart));
          
          this.updateTotalCart$.next(true);
          
        }
      }else{
        this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
      }
    },(error: any)=>{
      this.router.navigate(['/home/login']);
      // this.postLogOut({ userId: this._userInformation.pkyBill });
      if(this.guestFlag == '1'){
        this.toastrService.error(`Por favor, recarga la página e intentalo de nuevo`, '', {timeOut: 5000} );
      }else{
        this.toastrService.error(`Inicia sesión nuevamente para agregar productos al carrito`, '', {timeOut: 5000} );
      }
      // if(this.guestFlag == '1'){
      //   this.postGuestUser();
      // }
    });
  }

  postAddProductShoppingCartToSubscribe(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });
    
    return this.http.post(this._url + `/registerNewProductCart`, fields, { headers })
      
  }

  postRemoveProductShoppingCart(fields: any){
    if(this.guestFlag == '0'){
      let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`);
      
      this.http.post(this._url + `/model/SlsCartDetail/delete`, fields, { params })
        .subscribe(( resp: any )=>{
          if(resp.status){
            this.getShoppingCart();
            
            if(this._shoppingCart.storage_cart_detail.length == 1){
                this._shoppingCart = [];
                this.hideShoppingWidget();
                localStorage.setItem('LaCascadaCart', JSON.stringify(this._shoppingCart));
            }

            this.toastrService.success(`Producto eliminado del carrito de compras`, '', {timeOut: 5000});
          }else{
            this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
          }
      });
    }else{
      
      if (this._shoppingCart.storage_cart_detail.length == 1) {
        this._shoppingCart = [];
        localStorage.setItem('LaCascadaCart', JSON.stringify(this._shoppingCart));
        window.location.reload();
      }else{
        for (let index = 0; index < this._shoppingCart.storage_cart_detail.length; index++) {
          // console.log(this._shoppingCart.storage_cart_detail[index].codCart);
          if(this._shoppingCart.storage_cart_detail[index].pkyCartDetail == fields.id){
            this._shoppingCart.storage_cart_detail.splice(index, 1);
          }
        }
        this.updateTotalCart$.next(true);
        localStorage.setItem('LaCascadaCart', JSON.stringify(this._shoppingCart));
      }
    }
  }

  postDeletecart(fields: any){
    let params = new HttpParams ()
      .set('Authorization', `Bearer ${this._auth_token}`);
      
    this.http.post(this._url + `/model/SlsCartDetail/delete`, fields, { params })
      .subscribe(( resp: any )=>{
        if(resp.status){
          this._shoppingCart = [];
          localStorage.removeItem('LaCascadaCart');
          this.getShoppingCart();
          // this.toastrService.success(`Producto eliminado del carrito de compras`, '', {timeOut: 5000});
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    });
  }

  postUpdateProductShoppingCart(fields: any){
    
    if(this.guestFlag == '0'){
      let headers = new HttpHeaders ({
        Authorization: `Bearer ${this._auth_token}`
      });
      
      this.http.post(this._url + `/model/SlsCartDetail/edit`, fields, { headers })
        .subscribe(( resp: any )=>{
          if(resp.status){
            // this.toastrService.success(`Carrito actualizado`, '', {timeOut: 5000});
            this.getShoppingCart();
          }else{
            this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
          }
      });
    }else{
      this._shoppingCart.storage_cart_detail.forEach(element => {
        if(element.pkyCartDetail == fields.id){
          element.fldQuantity = fields.fldQuantity;
        }
      });
      localStorage.setItem('LaCascadaCart', JSON.stringify(this._shoppingCart));
    }
  }
  
  postAddNewAddress(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });
    
    this.http.post(this._url + `/model/UserAddress/create`, fields, { headers })
      .subscribe(( resp: any )=>{
        if(resp.status){
          if(fields.favoriteAddress){
            this.favoriteAddress = resp.item.pkyAddress;
            localStorage.setItem('LaCascadaFavAdd', resp.item.pkyAddress);
          }
          this.getAddresses();
          this.toastrService.success(`Nueva dirección agregada`, '', {timeOut: 5000});
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    });
  }
  
  postAddNewAddressToSubscribe(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });
    
    return this.http.post(this._url + `/model/UserAddress/create`, fields, { headers })
      
  }

  postEdditAddress(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });
    
    this.http.post(this._url + `/model/UserAddress/edit`, fields, { headers })
      .subscribe(( resp: any )=>{
        if(resp.status){
          if(fields.favoriteAddress){
            this.favoriteAddress = resp.item.pkyAddress;
            localStorage.setItem('LaCascadaFavAdd', resp.item.pkyAddress);
          }else{
            this.favoriteAddress = 0; 
            localStorage.removeItem('LaCascadaFavAdd');
          }
          this.getAddresses();
          this.toastrService.success(`Dirección actualizada`, '', {timeOut: 5000});
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    });
  }
  
  postRemoveAddress(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });
    
    this.http.post(this._url + `/model/UserAddress/delete`, fields, { headers })
      .subscribe(( resp: any )=>{
        if(resp.status){
          if(fields.favoriteAddress){
            this.favoriteAddress = 0;
            localStorage.removeItem('LaCascadaFavAdd');
          }
          this.getAddresses();
          if(this._addresses.length == 1){
            this._addresses = [];
            localStorage.setItem('LaCascadaAddresses', JSON.stringify(this._addresses));
          }
          this.toastrService.success(`Dirección eliminada`, '', {timeOut: 5000});
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
        }
    },(error: any) =>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }
  
  postApplyCoupon(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });
    
    return this.http.post(this._url + `/validateCoupon`, fields, { headers });
  }

  postUpdatePurchase(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });

    this.loader.classList.add('show');
    
    this.http.post(this._url + `/model/SlsCart/edit`, fields, { headers })
      .subscribe(( resp: any )=>{
        if(resp.status){
          var newFields = {
            codCart: fields.id,
            codAddress: fields.codAddress,
            fldTaxNumber: fields.fldTaxNumber,
            fldTaxName: fields.fldTaxName,
            fldPhone: fields.fldPhone,
            codPaymentMethod: fields.codPaymentMethod
          }
          this.postFinishPurchase(newFields);
        }
    },(error: any) =>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
      this.loader.classList.add('show');
    });
  }
  
  postFinishPurchase(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });
    
    this.http.post(this._url + `/postSales`, fields, { headers })
      .subscribe(( resp: any )=>{
        if(resp.status){
          
          this.lastOrder = resp.item;
          localStorage.setItem('LaCascadaLastOrder', JSON.stringify(this.lastOrder));
          this.toastrService.success(`Orden realizada`, '', {timeOut: 5000});
          this.router.navigate(['home/order-success']);
          this.getShoppingCart();
          this.loader.classList.remove('show');
        }else{
          this.toastrService.error(`${resp.message}`, '', {timeOut: 5000} );
      }
    },(error: any) =>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }
  
  postGuestUser(){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });
    
    this.http.post(this._url + `/loginUserGuest`, { headers })
      .subscribe(( resp: any )=>{
        if(resp.status){
          this._auth_token = resp.token;
          localStorage.setItem('LaCascadaToken', resp.token);
          localStorage.setItem('LaCascadaUser', JSON.stringify(resp.item.user));
          this._userInformation = resp.item.user;
        }
        
    },(error: any) =>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }

  postSendMail(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });
    
    this.http.post(this._url + `/helpMessage`, fields , { headers })
      .subscribe(( resp: any )=>{
        if(resp.status){
          this.toastrService.success(`Mensaje enviado con exito, te responderemos tan pronto como nos sea posible`, '', {timeOut: 5000});
        }
        
    },(error: any) =>{
      this.toastrService.error(`${error.error.message}`, '', {timeOut: 5000} );
    });
  }

  postSendMailToSubscribe(fields: any){
    let headers = new HttpHeaders ({
      Authorization: `Bearer ${this._auth_token}`
    });
    
    return this.http.post(this._url + `/helpMessage`, fields , { headers })
  }
}
