import { Component, OnInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { LaCascadaProductService } from '../../services/la-cascada-product.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public products: Product[] = [];
  


  constructor(private toastrService: ToastrService ,public laCascadaProductService: LaCascadaProductService, private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService) {
    this.productService.cartItems.subscribe(response => this.products = response);
    this.laCascadaProductService.getFavorites();
  }

  ngOnInit(): void {
  }



  get userInformation(){
    return this.laCascadaProductService.userInformation;
  }

  get shoppingCart(): any {
    return this.laCascadaProductService.shoppingCart;
  }

  get favorites(): any[] {
    return this.laCascadaProductService.favorites;
  }

  compareWindowLocation(path: string){
    return window.location.href.indexOf(`${path}`) > -1;
  }

  hideShoppingWidget(){
    if(!this.compareWindowLocation('shopping-cart-details') && !this.compareWindowLocation('location')){
      if(!this.laCascadaProductService.objetEmpty(this.shoppingCart)){
        this.laCascadaProductService.hideShoppingWidget();
      }else{
        this.toastrService.info(`Tu carrito de compras esta vacío.`, '', {timeOut: 5000} );
      }
    }
    
  }
}
